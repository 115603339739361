export default () => {

  const containers = document.querySelectorAll('[data-dropdown-container]');
  if (containers.length === 0) return;

  const OPENED = 'opened';

  const toggleDropdown = (button, dropdown, dropdownInner) => {
    const height = dropdownInner.clientHeight;

    if (!button.classList.contains(OPENED)) {
      button.classList.add(OPENED);
      dropdown.style.height = `${height}px`;
    } else {
      button.classList.remove(OPENED);
      dropdown.style.height = 0;
    }
  };

  containers.forEach(container => {
    const button = container.querySelector('[data-dropdown-button]');
    const dropdown = container.querySelector('[data-dropdown]');
    const dropdownInner = container.querySelector('[data-dropdown-inner]');

    button.addEventListener('click', e => {
      e.preventDefault();
      toggleDropdown(button, dropdown, dropdownInner);
    });
  });
}


/*----------------------------------hamburger menu----------------------------------*/
  
$('.header__burger').click(function () {
  var $this = $(this);

  if ($('.header__burger').hasClass('not-active')) {
    $('.header__burger').removeClass('not-active').addClass('is-active');
        $(".menu-primary-container").animate({
          right: 0
      }, 500);
        $('body').addClass('fixbody');
}
else if ($('.header__burger').hasClass('is-active')) {
  $('.header__burger').removeClass('is-active').addClass('not-active');
    $(".menu-primary-container").animate({
      right: -1000
  }, 500);
    $('body').removeClass('fixbody');
}
  return false;
});



/*menu accordion on mobile*/
var arrowclick = "<div class='menuarrow'><div class='menuarrowinner'></div></div>";

$('.menu-primary-container ul.menu > li.menu-item-has-children').append(arrowclick);

$('.menu-primary-container .menuarrow').click(function (e) {
  if($(this).parent().find("> .sub-menu").is(':visible')){
    $(this).parent().find("> .sub-menu").slideUp();
  } else {
    $(this).parent().find("> .sub-menu").slideDown();
  }
});


