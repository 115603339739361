export default () => {

  const burger = document.getElementById('header-burger-button');
  const menuContainer = document.getElementById('header-nav-mb');

  if(!menuContainer) {
    return
  }

  const overlay = document.getElementById('header-nav-overlay-mb');
  const OPENED = 'opened';


  // prevent visibility on load
  setTimeout(() => {
    menuContainer.style.visibility = 'visible';
  }, 200);

  const toggleMenu = e => {
    e.preventDefault();
    menuContainer.classList.toggle(OPENED);
    burger.classList.toggle(OPENED);
  };

  const closeMenu = () => {
    menuContainer.classList.remove(OPENED);
    burger.classList.remove(OPENED);

  };

  burger.addEventListener('click', toggleMenu);
  overlay.addEventListener('click', closeMenu);
}
