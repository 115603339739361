export default () => {
    let $header = document.querySelector('header.header');
    let headerHeight = $header.offsetHeight;
    if ($('body').hasClass('page-template-page-search-results-php')) {
        $('body').css('padding-top', 0 + 'px');
        return;
    }

    // Add the header height as padding to the body
    document.body.style.paddingTop = headerHeight + 'px';

    // Add class to header when scrolling
    window.addEventListener('scroll', () => {
        if (window.scrollY > 165) {
            $header.classList.add('scrolled');
        } else {
            $header.classList.remove('scrolled');
        }
    });
}
