export default () => {
  window.addEventListener('click', e => {
    const shareLink = e.target.closest('.social-share-link');
    if (shareLink) {
      e.preventDefault();
      const url = shareLink.href;
      window.open(url, "_blank", 'resizable=yes,scrollbars=yes,top=20,left=20,width=600,height=400');
    }
  });
}
